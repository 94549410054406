.statistic
    div.border
        height: 1px
        margin-bottom: 50px
        padding-bottom: 50px
        border-bottom: solid 1px #a5c2d1

.chart
    display: block
    width: 100%
    height: auto
    margin-bottom: 2em

.chart__legend
    width: auto
    margin-bottom: 1em 0
    padding: 1em
    font-size: 15px
    text-align: center
    
    .chart__legend__item
        display: inline-block
        margin: 0 1em
        
        .chart__legend__bullet
            display: inline-block
            width: 1em
            height: 1em
            margin-right: .5em
            vertical-align: middle